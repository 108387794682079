<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="clientData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching client data
      </h4>
      <div class="alert-body">
        No client found with this client id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-clients-list'}"
        >
          Client List
        </b-link>
        for other clients.
      </div>
    </b-alert>

    <template v-if="clientData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <client-view-client-info-card :client-data="clientData" />
        </b-col>
      </b-row>


    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import clientStoreModule from '../clientStoreModule'
import ClientViewClientInfoCard from './ClientViewClientInfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    ClientViewClientInfoCard,
    InvoiceList,
  },
  setup() {
    const clientData = ref(null)

    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-client/fetchClient', { id: router.currentRoute.params.id })
      .then(response => { 
        clientData.value = response.data })
      .catch(error => {
          clientData.value = undefined
      })

    return {
      clientData,
    }
  },
}
</script>

<style>

</style>
