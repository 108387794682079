<template>
  <b-card>

    <b-row>

      <!-- Client Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!--  & Action Buttons -->
        <div class="d-flex justify-content-start">
          
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ clientData.name }}
              </h4>
              
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-clients-edit', params: { id: clientData._id } }"
                variant="primary"
              >
                Edit
              </b-button>
             
            </div>
          </div>
        </div>

      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Name</span>
            </th>
            <td class="pb-50">
              {{ clientData.name }}
            </td>
          </tr>
          
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Description</span>
            </th>
            <td class="pb-50">
              {{ clientData.description }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientData.status }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Bet client</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientData.betClient }}
            </td>
          </tr>

           <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Token</span>
            </th>
            <td class="pb-50">
              {{ clientData.token }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Currencies</span>
            </th>
          </tr>

          <tr
            v-for="item in clientData.currencies"
            :key="item"
          >
            <th class="pb-50">
              
            </th>
            <td class="pb-50">
              {{ item }}
            </td>
          </tr>
         
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import roleStoreModule from '../../../pages/roles/roleStoreModule'
import store from '@/store'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const ROLE_APP_STORE_MODULE_NAME = 'app-role'
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule)
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLE_APP_STORE_MODULE_NAME)  
    })

    const roles = ref([])
     store.dispatch('app-role/fetchRoles')
      .then(response => { 
        roles.value = response.data.roles})

    return {
      roles,
    }
  },
  methods: {
    roleName(id) {
      const found = this.roles.find(element => element._id == id)
      if (found) {
        return found.name
      }
    },  
  }
  
}
</script>

<style>

</style>
